/*-----Seccion de Estilos Generales para los componentes-------*/
/*Remueve márgenes generados automáticamente por el navegador*/
* {
    margin: 0px;
    padding: 0px;
}
.pageSchema {
    position: relative;
    min-height: 100vh; /* Alto de la ventana */
    min-width: 100vw; /* Ancho de la ventana */
}
/*Añadir color de transparencia a texto*/
.textNoColor {
    color: #91474700;
}
/*-------------------------------------------------------------*/
/*--------------------Estilos del login------------------------*/
.Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #00304E;
}

.Auth-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: white;
}

.Auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
}

.Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 800;
}

label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
}

span.link-primary {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}
/*-------------------------------------------------------------*/
/*-----------Estilos para la barra de navegacion---------------*/
.barraContainer {
    background-color: #00304E;
}
.enlace {
    color: white;
    text-decoration: none;
}
/*-------------------------------------------------------------*/
/*------------------Estilos para la grafica---------------------*/
.apexcharts-menu-item.exportPNG {
    display: none;
}
/*-------------------------------------------------------------*/
/*------------------Estilos para el footer---------------------*/
footer {
	bottom: 0;
	left: 0;
	width: 100%;
	color: white;
	background-color: #00304E;
	text-align: center;
    position: absolute;
    padding-top: 10px;
}
/*-------------------------------------------------------------*/
@media (max-width: 640px) {
    footer {
        padding-top: 0px;
    }
}